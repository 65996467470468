import styled from 'styled-components'

export const StyledFooter = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: #fafafa;
        font-weight: normal;
        cursor: pointer;
    }

    a:visited {
        text-decoration: none;
        color: #fafafa;
    }

    a:hover {
        color: #61dafb;
    }
`