import styled from 'styled-components'

export const Container = styled.div`
    background: #282c34;
    color: #fafafa;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
`